import React from 'react'

const Litters = () => <div className="Container">
    <h1>C-kullen</h1>
    <h3>Födda 2022-06-05</h3>
    <p>3 hanar och 1 tik.</p>
    <img src={require('../assets/askas.jpeg')} alt='Gnistra kull'></img>
    <img src={require('../assets/askaAckoWeb.png')} alt='Aska kull'></img>
    <hr />
    <h1>B-kullen</h1>
    <h3>Födda 2022-06-01</h3>
    <p>4 hanar och 1 tik.</p>
    <img src={require('../assets/gnistror.jpeg')} alt='Gnistra kull'></img>
    <img src={require('../assets/blitz.jpeg')} alt='Gnistra kull'></img>
    <img src={require('../assets/gnistraLegoWeb.png')} alt='Gnistra kull'></img>
    <hr />
    <h1>A-kullen</h1>
    <h3>Födda 2021-07-20</h3>
    <p>2 hanar och 3 tikar. Samtliga svarta med ljusa tecken, se Instagram för bilder.</p>
    <p>Black Track’s Gnistra - Lapprots Texas Fudge</p>
</div>

export default Litters;